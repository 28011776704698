import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_SPOT_REQUEST_DETAILS_REQUEST, FetchSpotRequestDetailsRequestActionT } from './types';
import { fetchSpotRequestDetailsBegin, fetchSpotRequestDetailsError, fetchSpotRequestDetailsSuccess } from './actions';
import { selectSpotRequestDetails } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import spotCarrierTranziitApi from 'carrier/utils/api/spot-carrier-tranziit/api';
import { spotInboxRequestsStatsRefreshChannel } from 'carrier/store/spot-inbox-requests-stats/channels';

function* fetchSpotRequestDetailsSaga(action: FetchSpotRequestDetailsRequestActionT): WrapGeneratorT<void> {
    const { id, options } = action;

    const { fetchDetailsRequestStatus }: ReReturnT<typeof selectSpotRequestDetails> = yield select(
        selectSpotRequestDetails(id),
    );
    if (!checkNeedRequest(fetchDetailsRequestStatus, options)) {
        return;
    }

    yield put(fetchSpotRequestDetailsBegin(id));

    const [error, details]: ReturnApiT<typeof spotCarrierTranziitApi.fetchSpotRequestDetails> =
        yield spotCarrierTranziitApi.fetchSpotRequestDetails(id);
    if (error) {
        yield put(fetchSpotRequestDetailsError(id, error));
    } else {
        yield put(fetchSpotRequestDetailsSuccess(id, details));
    }

    spotInboxRequestsStatsRefreshChannel.emit({});
}

function* spotRequestDetailsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_SPOT_REQUEST_DETAILS_REQUEST, fetchSpotRequestDetailsSaga);
}

export default spotRequestDetailsSaga;
