import * as React from 'react';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { Trans, useTranslation } from 'react-i18next';
import TrailerDictIdFilterPill from 'common/components/filter-pills/TrailerDictIdFilterPill/TrailerDictIdFilterPill';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { useSelector } from 'react-redux';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import { formatDateRangeFilterValue } from 'common/components/FilterPills/pill-renderers';

type PropsT = {
    isCompact?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, setQueryFilters } = props;

    const { t } = useTranslation();

    const dictTrailerById = useSelector(selectTrailersDictById);

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('spot-requests.list.filters.pills.search', {
                        value: queryFilters[QueryFiltersKeysEnum.searchText],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.searchText],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('spot-requests.list.filters.pills.pickup-address', {
                        value: queryFilters[QueryFiltersKeysEnum.pickupAddress],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.pickupAddress],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('spot-requests.list.filters.pills.dropoff-address', {
                        value: queryFilters[QueryFiltersKeysEnum.dropOffAddress],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dropOffAddress],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const createdDateFrom = queryFilters[QueryFiltersKeysEnum.createdDateFrom];
                    const createdDateTo = queryFilters[QueryFiltersKeysEnum.createdDateTo];

                    return t('spot-requests.list.filters.pills.created-date', {
                        value: formatDateRangeFilterValue(createdDateFrom, createdDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdDateFrom, QueryFiltersKeysEnum.createdDateTo],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const selectedTrailerTypeId = queryFilters[QueryFiltersKeysEnum.dictTrailerTypeId];

                    return (
                        <Trans
                            i18nKey="spot-requests.list.filters.pills.trailer"
                            components={{
                                value: <TrailerDictIdFilterPill trailerDictId={selectedTrailerTypeId} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.dictTrailerTypeId],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const pickupDateFrom = queryFilters[QueryFiltersKeysEnum.pickupDateFrom];
                    const pickupDateTo = queryFilters[QueryFiltersKeysEnum.pickupDateTo];

                    return t('spot-requests.list.filters.pills.pickup', {
                        value: formatDateRangeFilterValue(pickupDateFrom, pickupDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.pickupDateFrom, QueryFiltersKeysEnum.pickupDateTo],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const dropOffDateFrom = queryFilters[QueryFiltersKeysEnum.dropOffDateFrom];
                    const dropOffDateTo = queryFilters[QueryFiltersKeysEnum.dropOffDateTo];

                    return t('spot-requests.list.filters.pills.drop-off', {
                        value: formatDateRangeFilterValue(dropOffDateFrom, dropOffDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dropOffDateFrom, QueryFiltersKeysEnum.dropOffDateTo],
            },
        ],
        [t, dictTrailerById],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
        />
    );
});

export default SelectedFilters;
