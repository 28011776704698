import {
    FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_BEGIN,
    FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_ERROR,
    FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_SUCCESS,
    SpotInboxRequestsStatsActionT,
    SpotInboxRequestsStatsStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import { ApiBidStatusT, BidStatusEnum } from 'carrier/utils/api/spot-carrier-tranziit/models';
import isNumber from 'lodash/isNumber';

const initialState: SpotInboxRequestsStatsStateT = {
    stats: null,
    fetchRequest: requestStatus.initial,
};

export default (
    state = initialState,
    action: SpotInboxRequestsStatsActionT | DestroySessionActionT,
): SpotInboxRequestsStatsStateT => {
    switch (action.type) {
        case FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }

        case FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
            };
        }

        case FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_SUCCESS: {
            const { statistics } = action;

            const initStats: Record<ApiBidStatusT, number> = {
                [BidStatusEnum.new]: 0,
                [BidStatusEnum.open]: 0,
                [BidStatusEnum.accepted]: 0,
                [BidStatusEnum.rejected]: 0,
                [BidStatusEnum.expired]: 0,
                [BidStatusEnum.won]: 0,
                [BidStatusEnum.lost]: 0,
                [BidStatusEnum.missed]: 0,
                [BidStatusEnum.canceled]: 0,
            };

            const stats = (statistics || []).reduce((acc, statistic) => {
                const { status, count } = statistic;

                if (status && isNumber(count)) {
                    acc[status] = count;
                }

                return acc;
            }, initStats);
            return {
                ...state,
                stats,
                fetchRequest: requestStatus.ok,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
