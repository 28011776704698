import {
    FETCH_SPOT_REQUEST_DETAILS_REQUEST,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS,
    FetchSpotRequestDetailsRequestActionT,
    FetchSpotRequestDetailsRequestBeginActionT,
    FetchSpotRequestDetailsRequestErrorActionT,
    FetchSpotRequestDetailsRequestSuccessActionT,
} from './types';
import { SpotRequestDetailsT } from './models';

export const fetchSpotRequestDetails = (
    id: SpotRequestIdT,
    options?: FetchOptionsT,
): FetchSpotRequestDetailsRequestActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST,
    id,
    options,
});

export const fetchSpotRequestDetailsBegin = (id: SpotRequestIdT): FetchSpotRequestDetailsRequestBeginActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN,
    id,
});

export const fetchSpotRequestDetailsSuccess = (
    id: SpotRequestIdT,
    details: SpotRequestDetailsT | null,
): FetchSpotRequestDetailsRequestSuccessActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS,
    details,
    id,
});

export const fetchSpotRequestDetailsError = (
    id: SpotRequestIdT,
    error: Error,
): FetchSpotRequestDetailsRequestErrorActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR,
    error,
    id,
});
