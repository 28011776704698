import * as React from 'react';
import { useEffect } from 'react';

import classNames from 'classnames/bind';

import styles from './Bids.scss';
import { SpotBidT } from 'carrier/utils/api/carrier-tranziit/models';
import BidDetails from 'carrier/components/BaseSpotRequestDetailsSidebarContent/BidDetails/BidDetails';
import { useDispatch, useSelector } from 'react-redux';
import { selectDocumentsDictByType } from 'common/store/documents-dict/selectors';
import BidMessage from 'carrier/components/BaseSpotRequestDetailsSidebarContent/BidMessage/BidMessage';
import BigWarningIcon from 'common/icons/BigWarningIcon';
import { ApiDocumentTypeT, SpotBidStatusEnum } from 'common/utils/api/models';
import { urlFactory } from 'carrier/utils/urls';
import history from 'common/utils/history';
import { StyleGuideColorsEnum } from 'common/constants';
import BigSleepIcon from 'common/icons/BigSleepIcon';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { fetchDocumentsDict } from 'common/store/documents-dict/actions';
import { useTranslation } from 'react-i18next';

type PropsT = {
    bids: Array<SpotBidT>;
    activeBid: SpotBidT | null;
    onRejectBid: (bidId: BidIdT) => void;
    onOpenUserDetails: (userId: UserIdT | null) => void;
    missingCertificates: Array<string>;
};

const cx = classNames.bind(styles);

const Bids: React.FC<PropsT> = React.memo((props) => {
    const { bids, activeBid, onRejectBid, onOpenUserDetails, missingCertificates } = props;

    const firstBid = bids?.[0] || null;
    const isOnlyOneBid = bids.length === 1 && !!firstBid;

    const { t } = useTranslation();

    const { partnerType } = usePartnerContext();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDocumentsDict(partnerType));
    }, [partnerType]);

    const documentsDictByType = useSelector(selectDocumentsDictByType(partnerType));
    const firstMissingCertificates = missingCertificates?.[0];
    if (firstMissingCertificates) {
        return (
            <BidMessage
                icon={<BigWarningIcon />}
                title={t('bids.messages.required-license.title')}
                description={t('bids.messages.required-license.description', {
                    licenseName: documentsDictByType[firstMissingCertificates as ApiDocumentTypeT]?.name || '',
                })}
                action={t('bids.messages.required-license.action')}
                onActionClick={() => {
                    const link = urlFactory.optionalDocuments();
                    history.push(link);
                }}
            />
        );
    }

    if (!bids?.length) {
        return (
            <BidMessage
                icon={<BigWarningIcon baseColor={StyleGuideColorsEnum.brandAccent} />}
                title={t('bids.messages.empty.title')}
            />
        );
    }

    if (
        (isOnlyOneBid && firstBid?.status === SpotBidStatusEnum.open) ||
        (isOnlyOneBid && firstBid?.status === SpotBidStatusEnum.new)
    ) {
        return (
            <BidMessage
                className={cx('bid-message')}
                icon={<BigWarningIcon baseColor={StyleGuideColorsEnum.brandAccent} />}
                title={t('bids.messages.new-offer.title')}
                description={t('bids.messages.new-offer.description')}
            />
        );
    }

    if (isOnlyOneBid && firstBid?.status === SpotBidStatusEnum.missed) {
        return (
            <BidMessage
                icon={<BigSleepIcon />}
                title={t('bids.messages.missed.title')}
                description={
                    <>
                        <div>{t('bids.messages.missed.description-1')}</div>
                        <div>{t('bids.messages.missed.description-2')}</div>
                    </>
                }
            />
        );
    }

    return (
        <>
            {bids.map((bid) => (
                <BidDetails
                    isActiveBid={bid === activeBid}
                    key={bid.id}
                    bid={bid}
                    className={cx('bid')}
                    onOpenUserDetails={onOpenUserDetails}
                    onRejectBid={onRejectBid}
                />
            ))}
        </>
    );
});

export default Bids;
