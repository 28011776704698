import * as React from 'react';
import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import { fetchSpotRequestDetails } from 'carrier/store/spot-request-details/actions';
import { selectSpotRequestDetails } from 'carrier/store/spot-request-details/selectors';
import SpotRequestDetails from 'carrier/components/BaseSpotRequestDetailsSidebarContent/SpotRequestDetails/SpotRequestDetails';
import EntitySidebarSkeleton from 'common/components/order-details/EntitySidebarSkeleton/EntitySidebarSkeleton';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import SideBarLayoutContextWrap from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import MapTrigger, { MapTriggerPropsT } from 'common/components/MapTrigger/MapTrigger';

import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import ChangeBidFormFooterContent from './footers/ChangeBidFormFooterContent/ChangeBidFormFooterContent';
import StartPlaceBidFooterContent from 'carrier/components/BaseSpotRequestDetailsSidebarContent/footers/StartPlaceBidFooterContent/StartPlaceBidFooterContent';
import { selectUpdateBidRequest } from 'carrier/store/bids/selectors';
import SpotBidStatusPill from 'common/components/status-pill/SpotBidStatusPill/SpotBidStatusPill';
import { rejectBid } from 'carrier/store/bids/actions';
import RejectBidConfirmation, {
    RejectBidConfirmationDataT,
} from 'carrier/components/BaseSpotRequestDetailsSidebarContent/dialogs/RejectBidConfirmation/RejectBidConfirmation';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { getActiveBid } from 'carrier/store/spot-request-details/utils/get-active-bid';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { spotDetailsRefreshChannel } from 'carrier/store/spot-request-details/channels';
import { InferChannelEventT } from 'common/utils/view-event-channel';

type PropsT = {
    id: SpotRequestIdT;
    onClose: (() => void) | null | undefined;
    onOpenUserDetails: (userId: UserIdT | null) => void;
} & MapTriggerPropsT;

const BaseSpotRequestDetailsSidebarContent: React.FC<PropsT> = React.memo((props) => {
    const { id, onClose, isActiveMapTrigger, toggleMap, onOpenUserDetails } = props;

    const { partnerId } = usePartnerContext();

    const dispatch = useDispatch();

    const [isActiveChangingBid, setActiveChangingBid] = React.useState<boolean>(false);

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    React.useEffect(() => {
        dispatch(fetchSpotRequestDetails(id));
    }, [id, partnerId]);

    const refreshHandler = useCallback(
        ({ spotRequestId }: InferChannelEventT<typeof spotDetailsRefreshChannel>) => {
            if (spotRequestId === id) {
                dispatch(fetchSpotRequestDetails(id, { isForceUpdate: true }));
            }
        },
        [id],
    );
    useChannelSubscribe(spotDetailsRefreshChannel, refreshHandler);

    const { details, fetchDetailsRequestStatus } = useSelector(selectSpotRequestDetails(id));

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchSpotRequestDetails(id));
    }, [id, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const isShowOverlayLoader = fetchDetailsRequestStatus.loading;
    const isShowSkeleton = !details;

    const handleCloseSendingToSpot = () => {
        setActiveChangingBid(false);
    };

    const updateBidRequest = useSelector(selectUpdateBidRequest);
    React.useEffect(() => {
        if (updateBidRequest.ok) {
            setActiveChangingBid(false);
        }
    }, [updateBidRequest]);

    const handleStartChangeBid = () => {
        setActiveChangingBid(true);
    };

    const rejectBidConfirmation = useModalDialog<RejectBidConfirmationDataT>();
    const handleRejectBid = (bidId: BidIdT) => {
        rejectBidConfirmation.setData({
            spotRequestId: id,
            selectedBidId: bidId,
        });
    };

    const handleConfirmRejectBid = (data: RejectBidConfirmationDataT) => {
        dispatch(rejectBid(data.spotRequestId, data.selectedBidId));
    };

    useCloseModalDialogAfterRequest(updateBidRequest, [rejectBidConfirmation]);

    const activeBid = getActiveBid(details?.bids);

    const renderFooterNode = (): React.ReactNode => {
        const isAvailablePlaceBid = details?.status === 'OPEN'; // TODO use status enum
        if (!isActiveChangingBid && isAvailablePlaceBid) {
            return (
                <FooterSideBarLayout hasPaddings>
                    <StartPlaceBidFooterContent details={details} onStartChangeBid={handleStartChangeBid} />
                </FooterSideBarLayout>
            );
        }

        if (isActiveChangingBid) {
            return (
                <FooterSideBarLayout hasPaddings hasShadow>
                    <ChangeBidFormFooterContent details={details} onCancel={handleCloseSendingToSpot} />
                </FooterSideBarLayout>
            );
        }

        return null;
    };

    return (
        <SideBarLayoutContextWrap>
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    title={details?.number || null}
                    afterTitleNode={<SpotBidStatusPill isSymmetrical status={activeBid?.status} />}
                    afterDropdownNode={<MapTrigger isActiveMapTrigger={isActiveMapTrigger} toggleMap={toggleMap} />}
                    testSelector="spot-request-details"
                    onClose={onClose}
                />
                <HeaderSideBarLoader isShow={isShowOverlayLoader} />
            </HeaderSideBarLayout>
            <SideBarLayout testSelector="spot-request-details">
                {isShowSkeleton ? (
                    <EntitySidebarSkeleton />
                ) : (
                    <SpotRequestDetails onOpenUserDetails={onOpenUserDetails} id={id} onRejectBid={handleRejectBid} />
                )}
            </SideBarLayout>
            {renderFooterNode()}
            <RejectBidConfirmation
                requestStatus={updateBidRequest}
                data={rejectBidConfirmation.data}
                onConfirmation={handleConfirmRejectBid}
                onCancel={rejectBidConfirmation.onCancel}
                onClose={rejectBidConfirmation.onClose}
            />
        </SideBarLayoutContextWrap>
    );
});

export default BaseSpotRequestDetailsSidebarContent;
